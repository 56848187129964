/** AvCan CSS variables **/
:root {
    --brand-primary: #245eac;
    --primary: #245eac;
    --brand-secondary: #e6252f;
    --secondary: #0f0f0f;

    --black: #0f0f0f;
    --white: #ffffff;

    /* Fallback gray colours (light/dark mode compatible); MUST BE REPEATED BELOW */

    --gray-darker: var(--color-step-900);
    --gray-dark: var(--color-step-700);
    --gray: var(--color-step-500);
    --gray-light: var(--color-step-300);
    --gray-lighter: var(--color-step-100);
    --gray-lightest: var(--color-step-50);

    --success: #4caf50;
    --info: #03a9f4;
    --warning: #ffc107;
    --danger: #e6252f;

    --error: #e6252f;

    --component-primary-bg: var(--primary);
    --component-primary-bg-hover: #246ccc;
    --component-primary-bg-active: #244b7f;
    --component-primary-bg-inactive: var(--primary);

    --rsbs-backdrop-bg: transparent;
    --rsbs-handle-bg: #7f7f7f;
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 16px;

    /* Light/Dark mode colours */

    --background-color: #ffffff;
    --background-color-secondary: #f5f5f5;
    --shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    --rsbs-bg: #ffffff;
    --text-color: #000000;
    --text-color-inverse: #ffffff;
    --color-primary: #1976d2;

    --color-step-50: #f2f2f2;
    --color-step-100: #e6e6e6;
    --color-step-150: #d9d9d9;
    --color-step-200: #cccccc;
    --color-step-250: #bfbfbf;
    --color-step-300: #b3b3b3;
    --color-step-350: #a6a6a6;
    --color-step-400: #999999;
    --color-step-450: #8c8c8c;
    --color-step-500: #808080;
    --color-step-550: #737373;
    --color-step-600: #666666;
    --color-step-650: #595959;
    --color-step-700: #4d4d4d;
    --color-step-750: #404040;
    --color-step-800: #333333;
    --color-step-850: #262626;
    --color-step-900: #191919;
    --color-step-950: #0d0d0d;
}

@media (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */

    body {
        --background-color: #121212;
        --background-color-secondary: #252525;
        --shadow: rgb(176 176 176 / 24%) 0px 1px 5px;
        --rsbs-bg: #121212;
        --text-color: #ffffff;
        --text-color-inverse: #000000;
        --color-primary: #90caf9;

        --color-step-50: #1e1e1e;
        --color-step-100: #2a2a2a;
        --color-step-150: #363636;
        --color-step-200: #414141;
        --color-step-250: #4d4d4d;
        --color-step-300: #595959;
        --color-step-350: #656565;
        --color-step-400: #717171;
        --color-step-450: #7d7d7d;
        --color-step-500: #898989;
        --color-step-550: #949494;
        --color-step-600: #a0a0a0;
        --color-step-650: #acacac;
        --color-step-700: #b8b8b8;
        --color-step-750: #c4c4c4;
        --color-step-800: #d0d0d0;
        --color-step-850: #dbdbdb;
        --color-step-900: #e7e7e7;
        --color-step-950: #f3f3f3;

        /* Repeated from above */

        --gray-darker: var(--color-step-900);
        --gray-dark: var(--color-step-700);
        --gray: var(--color-step-500);
        --gray-light: var(--color-step-300);
        --gray-lighter: var(--color-step-100);
        --gray-lightest: var(--color-step-50);
    }
}
