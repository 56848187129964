/* Some good defaults */
html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden; /* Disables pull-to-refresh on iOS, which causes all kinds of issues like non-static window.innerHeight calculations */
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    color: var(--text-color, black);
}

a {
    color: var(--color-primary);
    text-decoration: underline;
}
a.MuiListItem-root {
    color: var(--text-colour);
}

* {
    box-sizing: border-box;
}

#__next,
#__next > div {
    height: 100%;
}

h2 {
    margin-top: 0;
    line-height: 1.2;
}

.small {
    font-size: 0.9rem;
}
.centred {
    text-align: center;
}

/* Dark colour scheme map controls */
@media (prefers-color-scheme: dark) {
    .mapboxgl-ctrl-group {
        background: var(--tab-bar-background, var(--background-color, black)) !important;
    }

    .mapboxgl-ctrl-group button + button {
        border-top: 1px solid #3e474d !important;
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E %3C/svg%3E") !important;
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E %3C/svg%3E") !important;
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E %3Ccircle id='dot' cx='10' cy='10' r='2'/%3E %3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' display='none'/%3E %3C/svg%3E") !important;
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E%3Ccircle id='dot' cx='10' cy='10' r='2'/%3E%3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' fill='%23f00'/%3E%3C/svg%3E") !important;
    }
}

/* Photoswipe captions */
.pswp__custom-caption {
    background: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    color: #fff;
    width: calc(100% - 32px);
    max-width: 400px;
    padding: 2px 8px;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
}
.pswp__custom-caption p {
    margin: var(--s-2) 0;
}
.no-caption {
    display: none;
}

/* Photoswipe custom CSS */
.pswp__top-bar {
    opacity: 1 !important;
}

/* Remove the default margin above p tags in the caption 
(still part of photoswipe custom CSS */
.captionDiv > p {
    margin: 0;
}

/* Manually add some left margin since it appears to be missing */
.pswp__counter {
    margin-left: 10px;
}
